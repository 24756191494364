<script setup lang="ts">
  import { filesize } from 'filesize'
  import { formatText } from '~/utils/format-text'
  import type { PloneFile } from '~/types/Plone'

  const props = defineProps<{
    title: string
    outlined?: boolean
    file: PloneFile
  }>()

  const ext = (/[^./\\]*$/.exec(props.file?.filename) || [''])[0]

  const size = computed(() => {
    if (!props.file?.size) {
      return
    }
    return isNumber(props.file.size)
      ? filesize(props.file.size, { round: 0 })
      : props.file.size
  })
</script>

<template>
  <app-card
    :href="file?.download"
    :outlined="outlined"
    target="_blank"
    class="app-attachment"
    layout="row">
    <template #header>
      <h3 class="app-attachment__title">
        {{ formatText(title) }}
      </h3>
    </template>
    <template #footer>
      <div class="app-attachment__meta">
        <div class="app-attachment__ext">
          .{{ ext }}
        </div>
        <div class="app-attachment__size">
          {{ size }}
        </div>
        <div class="app-attachment__icon">
          <mdicon name="cloud-download-outline"/>
        </div>
      </div>
    </template>
  </app-card>
</template>

<style scoped>
  .app-attachment__title {
    color: var(--c-primary);
    text-decoration: underline;
    margin-bottom: var(--gap-200);
    @media (--sm) {
      margin-bottom: 0;
    }
  }

  .app-attachment:hover .app-attachment__title {
    text-decoration: none;
  }

  .app-attachment__meta {
    display: grid;
    grid-template-columns: 4fr 5fr 3fr;
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-200);
    align-items: center;
    & > * {
      display: flex;
      @media (--sm) {
        justify-content: flex-end;
      }
    }
  }

  .app-attachment__ext {
    text-transform: uppercase;
  }

  .app-attachment__icon {
    color: var(--c-primary);
    line-height: 1;
    justify-content: flex-end;
  }
</style>
